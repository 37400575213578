export const environment = {
  firebase: {
    projectId: 'b-card-dev',
    appId: '1:380176845515:web:21d91bd5a34bb756a3a94e',
    storageBucket: 'b-card-dev.appspot.com',
    locationId: 'asia-east1',
    apiKey: 'AIzaSyBEz0bP1U_7xqLSjMeVVP2jZmyXhPv09Po',
    authDomain: 'b-card-dev.firebaseapp.com',
    messagingSenderId: '380176845515',
    measurementId: 'G-ENETVVVHE3',
  },
  dynamicLinkInfo: {
    domainUriPrefix: 'https://dev.link.b-card.io',
    linkPrefix: 'https://dev.b-card.io',
    androidPackage: 'io.bcard.bcard.dev',
    iOSBundleID: 'io.bcard.bcard.dev',
  },
};
