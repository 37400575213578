import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LandingComponent } from './landing/landing.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { CardEntryComponent } from './card-entry/card-entry.component';
import { ProfileDetailComponent } from './profile-detail/profile-detail.component';
import { InstagramComponent } from './instagram/instagram.component';
import { FacebookComponent } from './facebook/facebook.component';
import { FeedbacksComponent } from './feedbacks/feedbacks.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';

const routes: Routes = [
  { path: '', component: LandingComponent },
  { path: 'c/:code', component: CardEntryComponent },
  { path: 'instagram', component: InstagramComponent },
  { path: 'facebook', component: FacebookComponent },
  { path: 'feedbacks', component: FeedbacksComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'not-found', component: PageNotFoundComponent },
  { path: ':id', component: ProfileDetailComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
