<div class="container-fluid p-0" *ngIf="profile; else loading">

  <div class="open-header">
    <div class="d-flex p-2 align-items-center">
      <div>
        <img src="/assets/appIcon.svg" class="open-header-icon" alt="">
      </div>
      <div class="p-2 flex-fill">
        <div class="open-header-title">B Card</div>
        <div class="open-header-subtitle">在「B Card」APP中打開</div>
      </div>
      <div>
        <button type="button" class="btn btn-light open-header-button" (click)="onOpenApp()">打開</button>
      </div>
    </div>
  </div>

  <ng-container *ngIf="profile['selected-template'] === 'pearl-white'; else secondTemplate">
    <div class="p-3 peral-white-background">

      <div class="p-3 peral-white-card">

        <div class="row">
          <div class="col text-center">
            <img src="{{ getCurrentContent().avatarUrl || '/assets/iconPerson.svg' }}" class="rounded-circle avatar" alt="">
          </div>
        </div>

        <div class="row mt-3" *ngIf="getCurrentContent().name">
          <div class="col peral-white-text-primary">{{ getCurrentContent().name }}</div>
        </div>

        <div class="row mt-3" *ngIf="getCurrentContent().position">
          <div class="col peral-white-text-secondary">{{ getCurrentContent().position }}</div>
        </div>

        <div class="row mt-2" *ngIf="getCurrentContent().company">
          <div class="col peral-white-text-third">{{ getCurrentContent().company }}</div>
        </div>

        <div class="mt-3 d-flex flex-row justify-content-center">
          <div *ngFor="let socialLink of getCurrentContent().socialLinks">
            <button type="button" class="btn p-1" (click)="onSocialClick(socialLink)">
              <img class="rounded" src="/assets/social-icons/{{ socialFormat[socialLink.code].icon }}.svg">
            </button>
          </div>
        </div>

      </div>

      <div class="mt-2 p-3 peral-white-card">

        <div class="row" *ngIf="getCurrentContent().showPhone !== false">
          <div class="col d-flex flex-column">
            <div class="text-start peral-white-text-secondary">主要</div>
            <div class="text-start peral-white-text-body mt-1">
              <a href="tel:+{{ profile.phoneCountryCode }}{{ profile.phone }}">
                +{{ profile.phoneCountryCode }} {{ profile.phone }}
              </a>
            </div>
          </div>
        </div>

        <div *ngIf="getCurrentContent().otherPhones && getCurrentContent().otherPhones.length > 0">
          <div class="row mt-3" *ngFor="let phone of getCurrentContent().otherPhones">
            <div class="col d-flex flex-column">
              <div class="text-start peral-white-text-secondary">{{ phoneTypes[phone.type] }}</div>
              <div class="text-start peral-white-text-body mt-1">
                <a href="tel:+{{ phone.countryCode }}{{ phone.number }}">
                  +{{ phone.countryCode }} {{ phone.number }} {{ phone.ext || '' }}
                </a>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="mt-2 p-3 peral-white-card" *ngIf="getCurrentContent().email">

        <div class="row">
          <div class="col d-flex flex-column">
            <div class="text-start peral-white-text-secondary">電子信箱</div>
            <div class="text-start peral-white-text-body mt-1">
              <a href="mailto:{{ getCurrentContent().email }}">
                {{ getCurrentContent().email }}
              </a>
            </div>
          </div>
        </div>

      </div>

      <div class="mt-2 p-3 peral-white-card" *ngIf="getCurrentContent().company">

        <div class="row">
          <div class="col d-flex align-items-center">
            <div class="flex-fill">
              <div class="d-flex flex-column">
                <div class="text-start peral-white-text-secondary">公司資訊</div>
                <div class="text-start peral-white-text-body mt-1">
                  {{ getCurrentContent().company }}
                </div>
                <div class="text-start peral-white-text-body mt-1" *ngIf="getCurrentContent().companyAddress && getCurrentContent().showAddress !== false">
                  <a href="http://maps.google.com/?q={{ getCurrentContent().companyAddress }}">
                    {{ getCurrentContent().companyAddress }}
                  </a>
                </div>
              </div>
            </div>
            <div class="ml-1" *ngIf="getCurrentContent().companyIcon">
              <img src="{{ getCurrentContent().companyIcon }}" class="company-avatar" alt="">
            </div>
          </div>
        </div>

      </div>

      <div class="mt-2 p-3 peral-white-card" *ngIf="getCurrentContent().companyTIN">

        <div class="row">
          <div class="col d-flex flex-column">
            <div class="text-start peral-white-text-secondary">統一編號</div>
            <div class="text-start peral-white-text-body mt-1">
              {{ getCurrentContent().companyTIN }}
            </div>
          </div>
        </div>

      </div>

      <div class="mt-2 p-3 peral-white-card" *ngIf="getCurrentContent().socialLinks && getCurrentContent().socialLinks.length > 0">

        <div class="row">
          <div class="col d-flex flex-column">
            <div class="text-start peral-white-text-secondary">社群連結</div>
            <div class="mt-1 d-flex flex-row justify-content-start align-items-center flex-wrap gap-2">
              <div *ngFor="let socialLink of getCurrentContent().socialLinks">
                <button type="button" class="btn peral-white-social-button" (click)="onSocialClick(socialLink)">
                  <img class="rounded" src="/assets/social-icons/{{ socialFormat[socialLink.code].icon }}.svg">
                  {{ socialLink.title || socialFormat[socialLink.code].name }}
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="mt-2 p-3 peral-white-card" *ngIf="getCurrentContent().intro">

        <div class="row">
          <div class="col d-flex flex-column">
            <div class="text-start peral-white-text-secondary">服務簡介</div>
            <div class="text-start peral-white-text-body mt-1">
              {{ getCurrentContent().intro }}
            </div>
          </div>
        </div>

      </div>

    </div>
  </ng-container>

  <ng-template #secondTemplate>
    <ng-container *ngIf="profile['selected-template'] === 'professional'; else defaultTemplate">

      <div class="p-3 professional-background">

        <div class="p-3 professional-card">

          <div class="row">
            <div class="col text-center">
              <img src="{{ getCurrentContent().avatarUrl || '/assets/iconPerson.svg' }}" class="rounded-circle avatar" alt="">
            </div>
          </div>

          <div class="row mt-3" *ngIf="getCurrentContent().name">
            <div class="col professional-text-primary">{{ getCurrentContent().name }}</div>
          </div>

          <div class="row mt-3" *ngIf="getCurrentContent().position">
            <div class="col professional-text-secondary">{{ getCurrentContent().position }}</div>
          </div>

          <div class="row mt-2" *ngIf="getCurrentContent().company">
            <div class="col professional-text-third">{{ getCurrentContent().company }}</div>
          </div>

          <div class="mt-3 d-flex flex-row justify-content-center">
            <div *ngFor="let socialLink of getCurrentContent().socialLinks">
              <button type="button" class="btn p-1" (click)="onSocialClick(socialLink)">
                <img class="rounded" src="/assets/social-icons/{{ socialFormat[socialLink.code].icon }}.svg">
              </button>
            </div>
          </div>

        </div>

        <div class="mt-2 p-3 professional-card" *ngIf="getCurrentContent().intro">

          <div class="row">
            <div class="col d-flex flex-column">
              <div class="text-start professional-text-secondary">服務簡介</div>
              <div class="text-start professional-text-body mt-1">
                {{ getCurrentContent().intro }}
              </div>
            </div>
          </div>

        </div>

        <div class="mt-2 p-3 professional-card">

          <div class="row" *ngIf="getCurrentContent().showPhone !== false">
            <div class="col d-flex flex-column">
              <div class="text-start professional-text-secondary">主要</div>
              <div class="text-start professional-text-body mt-1">
                <a href="tel:+{{ profile.phoneCountryCode }}{{ profile.phone }}">
                  +{{ profile.phoneCountryCode }} {{ profile.phone }}
                </a>
              </div>
            </div>
          </div>

          <div *ngIf="getCurrentContent().otherPhones && getCurrentContent().otherPhones.length > 0">
            <div class="row mt-3" *ngFor="let phone of getCurrentContent().otherPhones">
              <div class="col d-flex flex-column">
                <div class="text-start professional-text-secondary">{{ phoneTypes[phone.type] }}</div>
                <div class="text-start professional-text-body mt-1">
                  <a href="tel:+{{ phone.countryCode }}{{ phone.number }}">
                    +{{ phone.countryCode }} {{ phone.number }} {{ phone.ext || '' }}
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3" *ngIf="getCurrentContent().email">
            <div class="col d-flex flex-column">
              <div class="text-start professional-text-secondary">電子信箱</div>
              <div class="text-start professional-text-body mt-1">
                <a href="mailto:{{ getCurrentContent().email }}">
                  {{ getCurrentContent().email }}
                </a>
              </div>
            </div>
          </div>

        </div>

        <div class="mt-2 p-3 professional-card" *ngIf="getCurrentContent().company">

          <div class="row">
            <div class="col d-flex align-items-center">
              <div class="flex-fill">
                <div class="d-flex flex-column">
                  <div class="text-start professional-text-secondary">公司資訊</div>
                  <div class="text-start professional-text-body mt-1">
                    {{ getCurrentContent().company }}
                  </div>
                  <div class="text-start professional-text-body mt-1" *ngIf="getCurrentContent().companyAddress && getCurrentContent().showAddress !== false">
                    <a href="http://maps.google.com/?q={{ getCurrentContent().companyAddress }}">
                      {{ getCurrentContent().companyAddress }}
                    </a>
                  </div>
                </div>
              </div>
              <div class="ml-1" *ngIf="getCurrentContent().companyIcon">
                <img src="{{ getCurrentContent().companyIcon }}" class="company-avatar" alt="">
              </div>
            </div>
          </div>

          <div class="row mt-2" *ngIf="getCurrentContent().companyTIN">
            <div class="col d-flex flex-column">
              <div class="text-start professional-text-secondary">統一編號</div>
              <div class="text-start professional-text-body mt-1">
                {{ getCurrentContent().companyTIN }}
              </div>
            </div>
          </div>

        </div>

        <div class="mt-2 p-3 professional-card" *ngIf="getCurrentContent().socialLinks && getCurrentContent().socialLinks.length > 0">

          <div class="row">
            <div class="col d-flex flex-column gap-2">
              <div class="text-start professional-text-secondary">社群連結</div>
              <div class="mt-1 d-flex flex-column gap-1">
                <button type="button" class="btn professional-social-button p-0" (click)="onSocialClick(socialLink)" *ngFor="let socialLink of getCurrentContent().socialLinks">
                  <div class="d-flex">
                    <img class="rounded" src="/assets/social-icons/{{ socialFormat[socialLink.code].icon }}.svg">
                    <div class="flex-grow-1 text-start mx-2">
                      {{ socialLink.title || socialFormat[socialLink.code].name }}
                    </div>
                    <img src="/assets/iconKeyboardArrowRight.svg">
                  </div>
                </button>
              </div>
            </div>
          </div>

        </div>

      </div>

    </ng-container>
  </ng-template>

  <ng-template #defaultTemplate>
    <div class="p-5">
      <div class="row" *ngIf="getCurrentContent().company">
        <div class="col text-center h3">{{ getCurrentContent().company }}</div>
      </div>
      <div class="row mt-3">
        <div class="col text-center">
          <img src="{{ getCurrentContent().avatarUrl || '/assets/iconPerson.svg' }}" class="rounded-circle avatar" alt="">
        </div>
      </div>
      <div class="row mt-3" *ngIf="getCurrentContent().name">
        <div class="col text-center h3">{{ getCurrentContent().name }}</div>
      </div>
      <div class="row" *ngIf="getCurrentContent().position">
        <div class="col text-center p text-secondary">{{ getCurrentContent().position }}</div>
      </div>
    </div>

    <div class="lower-hat-container">
      <div class="lower-hat">
      </div>
    </div>

    <div class="px-5 pb-5 pt-1 lower-content">

      <div class="row" *ngIf="getCurrentContent().intro">
        <div class="col text-center p lh-base">{{ getCurrentContent().intro }}</div>
      </div>

      <div *ngIf="getCurrentContent().socialLinks && getCurrentContent().socialLinks.length > 0">

        <div class="row mt-3">
          <div class="col separator">社群連結</div>
        </div>

        <div class="row mt-3" *ngFor="let socialLink of getCurrentContent().socialLinks">
          <div class="col social-link d-grid">
            <button type="button" class="btn btn-block social-button" (click)="onSocialClick(socialLink)">
              <div class="d-flex">
                <img class="rounded" src="/assets/social-icons/{{ socialFormat[socialLink.code].icon }}.svg">
                <div class="flex-grow-1 text-start mx-2">
                  {{ socialLink.title || socialFormat[socialLink.code].name }}
                </div>
                <img src="/assets/iconKeyboardArrowRight.svg">
              </div>
            </button>
          </div>
        </div>

      </div>

      <div *ngIf="profile.phone && profile.phoneCountryCode">

        <div class="row mt-3">
          <div class="col separator">聯絡資訊</div>
        </div>

        <div class="row mt-3" *ngIf="getCurrentContent().showPhone !== false">
          <div class="col d-flex">
            <div class="text-start">主要</div>
            <div class="flex-grow-1 text-end ml-1 text-secondary">
              <a href="tel:+{{ profile.phoneCountryCode }}{{ profile.phone }}">
                +{{ profile.phoneCountryCode }} {{ profile.phone }}
              </a>
            </div>
          </div>
        </div>

        <div *ngIf="getCurrentContent().otherPhones && getCurrentContent().otherPhones.length > 0">
          <div class="row mt-3" *ngFor="let phone of getCurrentContent().otherPhones">
            <div class="col d-flex">
              <div class="text-start">{{ phoneTypes[phone.type] }}</div>
              <div class="flex-grow-1 text-end ml-1 text-secondary">
                <a href="tel:+{{ phone.countryCode }}{{ phone.number }}">
                  +{{ phone.countryCode }} {{ phone.number }} {{ phone.ext || '' }}
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3" *ngIf="getCurrentContent().email">
          <div class="col d-flex">
            <div class="text-start">電子郵件</div>
            <div class="flex-grow-1 text-end ml-1 text-secondary">
              <a href="mailto:{{ getCurrentContent().email }}">
                {{ getCurrentContent().email }}
              </a>
            </div>
          </div>
        </div>

      </div>

      <div *ngIf="getCurrentContent().company">

        <div class="row mt-3">
          <div class="col separator">公司資訊</div>
        </div>

        <div class="row mt-3">
          <div class="col text-center">{{ getCurrentContent().company }}</div>
        </div>

        <div class="row mt-3" *ngIf="getCurrentContent().companyAddress && getCurrentContent().showAddress !== false">
          <div class="col text-center">
            <a href="http://maps.google.com/?q={{ getCurrentContent().companyAddress }}">
              {{ getCurrentContent().companyAddress }}
            </a>
          </div>
        </div>

        <div class="row mt-3" *ngIf="getCurrentContent().companyTIN">
          <div class="col text-center">統一編號: {{ getCurrentContent().companyTIN }}</div>
        </div>

      </div>

    </div>
  </ng-template>

</div>

<ng-template #loading>
  <div class="container">
    <div class="text-center">
      <div class="entry-image">
        <img src="/assets/appIcon.svg" alt="" class="icon"/>
      </div>
    </div>
  </div>
</ng-template>
