<div class="container" *ngIf="card; else loading">
  <div *ngIf="card.activated; else notActivated">
    <div class="entry-image">
      <img src="/assets/appIcon.svg" alt="" class="icon"/>
    </div>
  </div>
  <ng-template #notActivated>

    <div class="row">
      <div class="col">
        <img src="/assets/appIcon.svg" alt="" class="icon"/>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col text-center h1">
        歡迎加入!
      </div>
    </div>

    <div class="row mt-4">
      <div class="col text-center">
        點擊下載 B Card 前往綁定您的卡片
      </div>
    </div>

    <div class="row mt-2">
      <div class="col">
        <app-store-buttons></app-store-buttons>
      </div>
    </div>

  </ng-template>
</div>

<ng-template #loading>
  <div class="container">
    <div class="text-center">
      <div class="entry-image">
        <img src="/assets/appIcon.svg" alt="" class="icon"/>
      </div>
    </div>
  </div>
</ng-template>
