import { Component } from '@angular/core';

@Component({
  selector: 'app-store-buttons',
  templateUrl: './store-buttons.component.html',
  styleUrls: ['./store-buttons.component.css']
})
export class StoreButtonsComponent {

}
