<div style="padding:3rem;">
  <h1>
    <strong>
      <strong>感謝您使用B Card！</strong>
    </strong>
  </h1>
  <p>
    本服務條款（以下簡稱條款、本條款）適用於您對 B Card 的使用，B Card（以下簡稱"此APP"）、網站（以下簡稱"網站"）以及 沐鑫媒體股份有限公司 Moose Media Co., Ltd（以下簡稱本公司「我們」）提供的任何相關服務（統稱服務「本服務」）下載和/或使用本服務，即表示您確認已閱讀並且同意接受本條款的約束，並遵守當地的所有法律法規所規範。
    <br><br>
    在本服務條款中，"您"、"您的 "或 "用戶 "指任何使用網站、APP應用或服務的個人或實體。除非另有說明，"我們 "或 "我們的 "將指沐鑫媒體股份有限公司 Moose Media Co., Ltd。
    <br><br>
    如果您的年齡在13歲以下，您不得使用我們的服務。如果您的年齡在13至18歲之間，您在此同意您的法定監護人已閱讀本條款，並同意您受本條款的約束。 這些條款和我們網站上發布的所有政策代表了您和此APP之間關於我們服務的全部協議。由於本公司的服務會不斷地進行審查並不時地發生變化，本公司可能會在不事先通知用戶的情況下修改這些條款。
  </p>
  <h1>
    <strong>
      <strong>一. B Card 提供之服務內容</strong>
    </strong>
  </h1>
  <p>
    B Card 是一款推廣業務服務的雲端名片平台，旨在以業務平台化、名片電子化、雲端化取代現下的紙本名片以及傳統業務流程，達到無接觸、環保、快速的業務服務與電子名片交換。其功能包括但不限於以下幾點。
<br><br>
*   您所有「公開」的電子虛擬名片都會顯示在此APP平台上，可被其他用戶即時搜索和聯繫。<br>
*   在會展、研討會和任何會議上與人進行無接觸、無紙化的電子名片交換。<br>
*   可即時在您的手機和電腦上新增名片並聯繫業務，同時同步您的名片。<br>
  </p>
  <h1>
    <strong>
      <strong>二.註冊和個人賬戶規範</strong>
    </strong>
  </h1>
  <p>
    您需要創建一個賬戶才能登入和使用本服務。註冊賬戶時提供的欄位資料應真實、準確、完整，在使用本服務時必須保持您的資料更新。您對在您的賬戶下使用本公司服務的所有行為負責，包括創建賬戶時提供的電話號碼。該電話號碼必須妥善保管，且只能由您本人使用。本公司可能會將使用該電話號碼進行的所有活動視為賬戶持有人本人的行為。如果您懷疑或發現您的賬戶被洩露，請盡快通知我們， info@b-card.io。
    <br><br>
    用戶得隨時刪除本服務帳戶並退出使用 B Card APP 及所有相關服務，本公司可以在用戶違反或企圖違反本服務條款時，不事先通知用戶的情況下暫停或刪除用戶的賬戶，本公司及本公司之關係企業將不對此所發生之損害承擔任何形式上的責任與賠償。
</p>
  <h1>
    <strong>
      <strong>三.使用條款與限制</strong>
    </strong>
  </h1>
  <p>
    本服務的用戶受以下條款的約束，但不限於此：
<br><br>
*   您必須至少年滿十三（13）歲或以上。<br>
*   在使用我們的服務時，並非為其他人註冊帳號，也不得冒充使用他人的姓名或手機號碼。<br>
*   您理解並同意對您通過 B Card 公開或私下傳送、選擇、散播、上傳、發布或分發的所有內容負責。在上傳或公開任何內容到 APP 之前，請確保您永遠遵守在當地的所有法律法規以及本條款所規範範圍內。<br>
*   您同意將您的內容無償或免費使用的權利交給我們，僅用於推廣、改進和發展我們的服務。<br>
*   您同意不會在未經他人同意的情況下收集任何個人資料。<br>
*   您同意我們可能會被要求保留或提供、披露您的內容，以遵守適用的法律或法規或其他適用的法律程序。<br>
*   您充分理解並同意， B Card 是一項線上服務，它可能會遇到各種風險，如天災、疾病、網路不可抗因素，可能導致臨時中斷服務。在此情況下，本公司將盡快恢復，但由上述因素不限於此，造成的責任和損失由使用者承擔。本公司將不承擔您任何直接或間接的損失責任。<br>
*   您承認並同意，如果您使用NFC功能交換名片，如您與其他用戶交換名片資料，應謹慎判斷信息的真實性，防範一切線上、線下可能發生的危害。<br>
*   如果您創建了一個用戶賬戶，您同意只用您的真實姓名和有效手機號碼創建一個賬戶。您還同意妥善保管您的手機號碼，且只有您本人才有登入您的賬戶的權利。您對使用您的賬戶所進行的活動承擔一切全部責任。<br>
</p>
  <h1>
    <strong>
      <strong>四.用戶內容授權與規範</strong>
    </strong>
  </h1>
  <p>
    當您使用 B Card 或與 APP 的其他用戶互動時，您可以選擇向本公司和/或本公司服務的其他用戶發送、上傳、傳送或以其他方式提供內容，如數據、文件、圖片、視頻、文字、聲音、您的地理位置信息及其他內容（統稱為"內容"）。但您同意，您發送、上傳、交流、傳輸或以其他方式提供的內容皆須遵守以下條款，但不限於此：
    <br><br>
    *   您同意並遵守，資料需具有確切真實性，您將對您所上傳、公開發送的任何內容負起所有相對應的法律責任。<br>
*   您同意並遵守，不得散播足以引誘、暗示、或其他促使他人進行任何違反法規、法律之非法交易訊息傳播。<br>
*   您同意並遵守，不侵犯、盜用或侵害任何第三方的權利，包括但不限於隱私權、宣傳權、版權、商標、肖像權和/或其他知識產權。<br>
*   您同意並遵守，不得違反任何現行法律或行政法規的強制性規定。<br>
*   您同意並遵守，在此APP內不得包含任何可被解釋為威脅、貶低、誹謗、色情、宗教、種族或民族攻擊、歧視、侮辱、誹謗或其他非法或不適當的訊息以及資料。<br>
*   您同意並遵守，在此APP內不得包含任何未經授權的廣告、促銷材料或任何其他形式的招攬。(如 垃圾郵件、連鎖信 、釣魚連結、病毒連結、網站等)<br>
*   您同意並遵守，不得使用任何自動化登入及非常態性操作方式蒐集、攻擊此 APP 及本服務的網站。<br>
*   您同意並遵守，在此 APP 內不得包含上傳任何您在法律法規上無權發布的資料與資訊。 (如內幕消息或其他機密消息)<br>
*   您同意並遵守，不反對貴國憲法的基本原則，不實施危害國家安全、洩露國家機密的行為。<br>
*   您同意並遵守，在此APP內發送的包含任何如數據、文件、圖片、文字、聲音、您的地理位置信息及其他內容，因本服務於眾多不可抗力之因素下無法保證訊息的即時性，將不適用於與自身，他人或團體生命相關的求救，呼救，尋求協助和緊急事件上。 若您不幸因此發生任何損失或傷害，本公司與任何相關合作夥伴將無法承擔您任何直接與間接的損失責任。<br>
*   您同意並遵守，任何發布訊息不得包含可能損害服務或其他用戶的病毒、鏈接、信息或技術。此APP對服務內發布的內容一概不負責，也不認可。<br>
*   B Card 不審查內容，因此對您提供的任何內容資訊或第三方內容或其中的資訊不負責任，不提供或以其他方式使用服務。本公司不對任何內容的刪除或丟失負責。您應對您的內容負責，並對任何違反本條款的內容承擔一切法律責任。在上傳或發送的訊息方面，您同意給予本公司免版稅和非獨家許可使用、複製、改編、修改、翻譯、傳播和再許可這些內容。您也同意授予本公司以自己的名義對侵犯上述資料訊息版權的人進行法律救濟的權利，並獲得所有的賠償。請確保您有所有必要的權利來授予我們此類許可。<br>
  </p>
  <h1>
    <strong>
      <strong>五.檢舉與申訴</strong>
    </strong>
  </h1>
  <p>
    *   您同意並遵守，若所公開的名片資訊遭任何人或其他使用者檢舉，本公司將在收到檢舉描述後，依據服務條款做裁量進行審核，若被檢舉者坐實了違規事項，則依相關規則停權或直接刪除您的所有帳戶內容。<br>
*   您同意並遵守，若所公開的名片資訊遭任何人或其他使用者大量檢舉，本公司可不須經您的同意與通知，即先行停權或刪除您的帳戶。<br>
*   若有任何帳戶停權與刪除問題，本公司建議您可以通過以下連結與我們聯繫：info@b-card.io。<br>
  </p>
  <h1>
    <strong>
      <strong>七.賬戶刪除</strong>
    </strong>
  </h1>
  <p>
    *   註冊用戶可自行於 APP 中刪除其 B Card 帳號，本服務會於刪除帳號動作中提供二次確認於該用戶並告知確切刪除流程天數（7個工作天），用以保護用戶資料與刪除帳戶之後果。另，自帳號刪除流程天數起，此帳號在本服務的所有服務使用權將被本公司完全禁止。您同意即便為不慎刪除帳號，本公司也無法回復您的帳號內容，請再次留意。
  </p>
  <h1>
    <strong>
      <strong>八. 帳戶遭盜用刪除之退款行為</strong>
    </strong>
  </h1>
  <p>
    當用戶帳號發生資料竊取導致帳戶停權或被刪除時，用戶應準備個人身份驗證文件與問題發生紀錄文件立即告知本公司，並由本公司確認此事件為非帳號使用者本人入侵行為時，本公司應提供該用戶建立新帳號或舊帳號部分資料回復動作（僅於技術可行範圍不可保證百分之百資料恢復），但您同意本公司恕不提供商品等值的任何法力效益 / 非法力效益交易貨幣或任何形式之賠償。
  </p>
  <h1>
    <strong>
      <strong>九.責任和賠償</strong>
    </strong>
  </h1>
  <p>
    B Card 包括其合作夥伴、附屬公司、承包商、高級職員、董事、僱員和代理人不對任何APP內利潤損失、數據損失或與服務有關的任何其他間接損害負責，也不對這些條款負責。 您同意賠償並使 B Card、其合作夥伴、附屬機構、承包商、高級職員、董事、僱員和代理人免受任何及所有與您違反這些條款有關的索賠、損失、責任、費用和損害。緊急電話不能為本服務處理任何緊急事務，如向消防隊、警察、海事安全局或其他緊急服務機構撥打緊急電話、簡訊或語音訊息。
<br><br>
對於用戶因任何不當違規行為所產生之損害與任何對第三人造成之不利益行為（脅迫、詐欺、威脅等非法不當之行為），本公司在此聲明將不負任何形式的責任。
  </p>
  <h1>
    <strong>
      <strong>十.隱私保護</strong>
    </strong>
  </h1>
  <p>我們將盡力採取一切合理的措施來保護您傳輸給我們或從我們的服務中獲得的資訊、訊息。</p>
  <h1>
    <strong>
      <strong>十一.廣告宣傳</strong>
    </strong>
  </h1>
  <p>本公司保留在服務中向用戶提供廣告、以及任何商業推廣的權利。</p>
  <h1>
    <strong>
      <strong>十二.聯繫我們</strong>
    </strong>
  </h1>
  <p>如果您對我們的服務或這些使用條款有任何疑問或諮詢，請通過info@b-card.io 與我們聯繫。</p>
</div>
