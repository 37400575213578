import { Component, OnInit, inject } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Firestore, collection, query, where, getDocs, doc, getDoc } from '@angular/fire/firestore';

@Component({
  selector: 'app-card-entry',
  templateUrl: './card-entry.component.html',
  styleUrls: ['./card-entry.component.css']
})
export class CardEntryComponent implements OnInit {

  code: string;
  firestore: Firestore = inject(Firestore);
  card?: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.code = this.route.snapshot.paramMap.get('code')!;
  }

  ngOnInit(): void {
    this.getCard(this.code);
  }

  async getCard(code: string) {
    const cardRef = doc(this.firestore, 'cards', code);
    const cardSnap = await getDoc(cardRef);
    if (cardSnap.exists()) {
      this.card = cardSnap.data();
      if (this.card.activated) {
        // Decide if this user has a displayID
        const uid = this.card.activated.uid;
        const profileRef = doc(this.firestore, 'profiles', uid);
        const profileSnap = await getDoc(profileRef);
        if (profileSnap.exists()) {
          const profile = profileSnap.data();
          this.router.navigate([`/${profile['displayID'] ?? uid}`]);
        } else {
          // Profile missing!
          this.router.navigate(['/not-found']);
        }
      }
    } else {
      this.router.navigate(['/not-found']);
    }
  }

}
