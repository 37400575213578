<div class="container-fluid content text-center py-3">
  <div class="row">
    <div class="col">
      下載 APP 使用更完整功能
    </div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <app-store-buttons></app-store-buttons>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <a routerLink="/terms">服務條款</a>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <a routerLink="/privacy">隱私政策</a>
    </div>
  </div>
</div>
