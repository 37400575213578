import { Component, OnInit, inject } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Firestore, collection, query, where, getDocs, doc, getDoc } from '@angular/fire/firestore';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-profile-detail',
  templateUrl: './profile-detail.component.html',
  styleUrls: ['./profile-detail.component.css']
})
export class ProfileDetailComponent implements OnInit {

  id: string;
  firestore: Firestore = inject(Firestore);
  profile?: any;
  dynamicLink?: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
  ) {
    this.id = this.route.snapshot.paramMap.get('id')!;
  }

  ngOnInit(): void {
    this.getProfile(this.id);
    this.createDynamicLink(this.id);
  }

  async getProfile(id: string) {
    // Try displayID first
    const displayIDQuery = query(collection(this.firestore, 'profiles'), where('displayID', '==', id));
    const displayIDSnapshot = await getDocs(displayIDQuery);
    if (!displayIDSnapshot.empty) {
      // Found the profile
      this.profile = displayIDSnapshot.docs[0].data();
      return;
    }
    const profileRef = doc(this.firestore, 'profiles', id);
    const profileSnap = await getDoc(profileRef);
    if (profileSnap.exists()) {
      // Found the profile
      this.profile = profileSnap.data();
    } else {
      // Profile missing!
      this.router.navigate(['/not-found']);
    }
  }

  async createDynamicLink(id: string) {
    const apiKey = environment.firebase.apiKey;
    const headers = { 'Content-Type': 'application/json' };
    const body = {
      dynamicLinkInfo: {
        domainUriPrefix: environment.dynamicLinkInfo.domainUriPrefix,
        link: `${environment.dynamicLinkInfo.linkPrefix}/${id}`,
        androidInfo: {
          androidPackageName: environment.dynamicLinkInfo.androidPackage,
        },
        iosInfo: {
          iosBundleId: environment.dynamicLinkInfo.iOSBundleID,
        }
      }
    };
    this.http.post<any>(
      `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${apiKey}`,
      body,
      { headers }
    ).subscribe({
      next: data => {
        this.dynamicLink = data.shortLink;
      },
      error: error => {
        //console.error('There was an error!', error);
      }
    })
  }

  getCurrentContent() {
    const mode = this.profile.altMode || 'default';
    if (!this.profile.altContents) {
      return this.profile;
    }
    const altContent = this.profile.altContents[mode];
    if (altContent) {
      return altContent;
    } else {
      return this.profile;
    }
  }

  onOpenApp() {
    if (this.dynamicLink) {
      window.open(this.dynamicLink, '_blank');
    }
  }

  onSocialClick(socialLink: any) {
    switch (socialLink.code) {
      case 'wechat':
        break;
      case 'telegram':
        window.open(`https://t.me/${socialLink.link}`, '_blank');
        break;
      case 'line':
        window.open(`https://line.me/R/ti/p/${socialLink.link}`, '_blank');
        break;
      case 'whatsapp':
        window.open(`https://api.whatsapp.com/send/?phone=${socialLink.link}&text&type=phone_number&app_absent=0`, '_blank');
        break;
      default:
        window.open(socialLink.link, '_blank');
        break;
    }
  }

  phoneTypes: any = {
    mobile: "手機",
    company: "公司",
    fax: "傳真",
    others: "其他",
  }

  socialFormat: any = {
    behance: {
      icon: "iconBehance",
      name: "Behance"
    },
    dribbble: {
      icon: "iconDribbble",
      name: "Dribbble"
    },
    facebook: {
      icon: "iconFacebook",
      name: "Facebook"
    },
    instagram: {
      icon: "iconInstagram",
      name: "Instagram"
    },
    linkedin: {
      icon: "iconLinkedin",
      name: "LinkedIn"
    },
    medium: {
      icon: "iconMedium",
      name: "Medium"
    },
    pinterest: {
      icon: "iconPinterest",
      name: "Pinterest"
    },
    snapchat: {
      icon: "iconSnapchat",
      name: "Snapchat"
    },
    tumblr: {
      icon: "iconTumblr",
      name: "Tumblr"
    },
    twitter: {
      icon: "iconTwitter",
      name: "Twitter"
    },
    youtube: {
      icon: "iconYoutube",
      name: "Youtube"
    },
    line: {
      icon: "iconLine",
      name: "Line"
    },
    wechat: {
      icon: "iconWechat",
      name: "微信"
    },
    whatsapp: {
      icon: "iconWhatsapp",
      name: "WhatsApp"
    },
    telegram: {
      icon: "iconTelegram",
      name: "Telegram"
    },
    tiktok: {
      icon: "iconTiktok",
      name: "抖音"
    },
    shopee: {
      icon: "iconShopee",
      name: "蝦皮"
    },
    foodpanda: {
      icon: "iconFoodpanda",
      name: "Foodpanda"
    },
    ubereats: {
      icon: "iconUbereats",
      name: "Uber Eats"
    },
    skype: {
      icon: "iconSkype",
      name: "Skype"
    },
    others: {
      icon: "iconOthers",
      name: "其他"
    }
  };


}
