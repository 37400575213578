<div class="container">

  <div class="row">
    <div class="col text-center h1">
      B Card
    </div>
  </div>

  <div class="row">
    <div class="col text-center">
      專屬於你的電子卡片
    </div>
  </div>

  <div class="row my-3">
    <div class="col">
      <img src="/assets/appIcon.svg" alt="" class="icon"/>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col">
      <app-store-buttons></app-store-buttons>
    </div>
  </div>
</div>
