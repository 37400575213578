<div style="padding:3rem;">
  <h1>
    <strong>
      <strong>使用條款</strong>
    </strong>
  </h1>
  <p>
    本隱私政策規定了BCARD（"BCARD"、"我們"、"我們"或"我們的"、"此APP"）和網站（"網站"）的隱私政策，統稱為"服務"、"本服務"。 通過使用我們的服務，您同意我們可以根據本隱私政策收集、使用和分享您的資料訊息。如果您不同意，您可以拒絕並不使用本服務。
本公司保留隨時修改或刪除本隱私政策部分內容的權利。本隱私政策是適用於網站、此APP或我們任何本服務的其他條款和條件的補充。
  </p>
  <h1>
    <strong>
      <strong>用戶資訊蒐集</strong>
    </strong>
  </h1>
  <p>
    使用本服務時，需要註冊一個賬戶，並由本公司獲取，您提供的個人資料和商業訊息以及此APP的操作過程。這可能包括但不限於以下內容：
    註冊 BCARD賬戶時，需要提供手機號碼和基本個人資訊。用於設置您專屬的 BCARD商業電子名片訊息。
    您認知並同意，註冊時任何用戶資訊都是代表您個人商業、業務身份的內容訊息，您應對任何內容訊息負責，例如個人照片、姓名、服務內容等。
    從您的設備聯絡人列表（或電話簿）中的聯絡人或聯絡人資訊，這些資訊可能被導入到此APP中或用於名片交換或邀請您的周遭朋友、親人。
    您在 BCARD內的名片資訊以及資料，將會由本公司蒐集獲取並保護所有資料的網路安全性。
    您在此APP內的部分操作流程會由本公司獲取資訊，以使我們的服務可以更好（IP地址，瀏覽器類型，瀏覽過的頁面，版本和語言，操作系統......等）。
  </p>
  <h1>
    <strong>
      <strong>用戶內容授權</strong>
    </strong>
  </h1>
  <p>您同意，您為本服務提供的"公開"資訊可以被他人以及此APP其他的使用者看到。您提供使用的個人和商業資訊可能會公開或允許他人了解您工作的服務資訊。當您創建或更新賬戶內容時，並向我們提供您的個人資訊時，即表示您接受我們的使用條款和隱私政策。我們可能將您的數據用於但不限於以下目的：

幫助您在登入後有效地接收訊息與資料。
提供個性化的內容和資訊。
開發和測試新產品及功能。
提供客戶反饋，以便診斷或修復與本服務有關的技術問題。
為了遵守法規、法律或任何政策的要求，我們可能會在，政策機構或其他行政官員需要或必要提供您的資訊時，而無需得到您的同意，並由本公司代為提供資訊。
</p>
  <h1>
    <strong>
      <strong>網站Cookies</strong>
    </strong>
  </h1>
  <p>使用本服務即表示您同意我們在服務中使用cookies或類似技術。 Cookies是網站向您的電腦發送的小型筆記文件，用於辨識您的瀏覽器或在您的瀏覽器中存儲資訊或設置。出於安全考慮，我們存儲的資訊不會包括用戶的ID和隱私訊息。

我們可能會使用Google Analytics來收集網站上的訪客統計信息。在 Google 創建的cookie，這裡有更詳細的描述：<a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage</a>

您可以通過在您訪問服務時使用的網絡瀏覽器的設置中關閉對cookies的接受來選擇不存儲cookies，您也可以刪除cookies。但請注意，如果沒有cookies，您可能無法使用服務的所有功能。
</p>
  <h1>
    <strong>
      <strong>安全性</strong>
    </strong>
  </h1>
  <p>我們努力確保提交給我們網站的個人資訊的安全性、完整性和隱私性，並根據當前的技術審查和更新我們的網路安全措施。不幸的是，沒有任何數據在互聯網上的傳輸可以完全保證是100%安全的。但是，我們將盡力採取一切合理的步驟方法，以保護您可能傳輸給我們或從我們的服務中傳輸的個人資訊。不過，對於任何此APP內數據的丟失或披露，或由於傳輸錯誤或第三方未經授權或非法行為或您決定披露您的個人資訊而造成的任何損失或損害，本公司概不負責。 此外，我們的員工和提供與我們的資訊系統有關的服務的承包商有義務尊重我們持有的任何個人資訊的保密、安全性。</p>
  <h1>
    <strong>
      <strong>變更所有權</strong>
    </strong>
  </h1>
  <p>在BCARD被出售、部分或全部轉讓給其他機構的情況下（如合併、收購、破產、解散或清算），您通過本服務收集的資訊可能會成為被出售或轉讓的項目之一。您通過本服務提供的資訊將繼續歸您所有，買方或受讓方必須繼續遵守履行本隱私政策中的承諾。</p>
</div>
