<div class="container">
  <div class="row">
    <div class="col text-center h1">
      找不到頁面
    </div>
  </div>

  <div class="row mt-4">
    <div class="col text-center">
      前往下載 B Card
    </div>
  </div>

  <div class="row mt-2">
    <div class="col">
      <app-store-buttons></app-store-buttons>
    </div>
  </div>
</div>
